import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Alert, Select, Form, message } from 'antd';
import * as _ from 'lodash';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';

import { WithdrawtModel } from '../../types';
import { makeKhanWithdraw } from '../../helpers/firebase_functions';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../routes/route_helper';
type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data: WithdrawtModel;
  goUser?:boolean
};

const DepositDetailModal: React.FC<Props> = ({ data, open = false,goUser=false, toggle }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const validate = () => {
    if (
      loading ||
      loadingSave
    )
      return false;
    return true;
  };
  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const res = await makeKhanWithdraw(data?.uid);
    console.log(res)
    if (res.status) {
      messageApi.open({
        type: 'success',
        content: "Амжилттай",
      });
      toggle(false)
    }
    else {
      messageApi.open({
        type: 'info',
        content: res.msg,
      });
    }
    setLoadingSave(false);

  };
  const redirectToUser=()=>{
    navigate(Routes.compilePath(Routes.depositAcc, { userID: data.userID,depoID: data.depAcc}));
  }

  const getFooter = () => {
    let buttons =[
      <Button
      key="submit"
      className="rounded-xl"
      onClick={() => toggle(false)}
    >
      Хаах
    </Button>,
    ]
    if(goUser){
      buttons.push( <Button
        key="submit"
   
        className="rounded-xl"
        onClick={redirectToUser}
      >
        Хэрэглэгч руу очих
      </Button>)
    }
    if (data.status  != 'Transferred') {
      buttons.push( 
        <Button
          key="Khansubmit"
          loading={loadingSave}
          className="rounded-xl"
          onClick={onSave}
        >
          Khan шилжүүлэг хийх
        </Button>
    )
    }
    return buttons

  }
  const onCancel = () => {
    setLoadingSave(false);
    toggle(false);
  };
  return (
    <>

      {data && (
        <>
          {contextHolder}
          <Modal
            open={open}
            title=""
            onCancel={onCancel}
            className="info-modal"
            centered
            footer={getFooter()}
          >
            <div className="dialog-body">

              <div className="dialog-content px-8">
                <div className="font-medium text-lg text-center mb-5">
                  Дэлгэрэнгүй
                </div>

                <Form
                  form={form}
                  onFinish={onSave}
                  initialValues={data}
                  scrollToFirstError
                >
                         <div className="font-medium">Withdraw ID</div>
                  <Form.Item
                    name="uid">
                    <Input
               
                    />
                  </Form.Item>
                  <div className="font-medium">Type</div>
                  <Form.Item
                    name="type">
                    <Input
               
                    />
                  </Form.Item>
                  <div className="font-medium">Status</div>
                  <Form.Item
                    name="status">
                    <Input
                
                    />
                  </Form.Item>
              {data.khanRes &&(
                <>
                    <div className="font-medium">Хаан гүйлгээ хариу</div>
                  <Form.Item
                    name="khanRes">
                    <Input
                      defaultValue={`${JSON.parse(data.khanRes).toString()}`}
                    />
                  </Form.Item>
                </>
              )

              }
              

                  <div className="font-medium">Status</div>
                  <Form.Item
                    name="status">
                    <Input
                    />
                  </Form.Item>
                  <div className="font-medium">Status Msg</div>
                  <Form.Item
                    name="statusMsg">
                    <Input
                     
                    />
                  </Form.Item>

                  <div className="font-medium">System д бүртгэгдсэн огноо</div>
                  <Form.Item
                    name="created_At">
                    <Input
                      defaultValue={convertDateTimeFromTimestamp(data.createdAt)}
                    />
                  </Form.Item>
                  <div className="font-medium">System д update хийсэн огноо</div>
                  <Form.Item
                    name="updated_At">
                    <Input
                      defaultValue={convertDateTimeFromTimestamp(data.updatedAt)}
                    />
                  </Form.Item>
                 


                </Form>
                {!_.isEmpty(error) && <Alert message={error} type="error" />}
              </div>

            </div>
          </Modal>

        </>
      )}
    </>
  );
};

export default DepositDetailModal;
