import {compile} from 'path-to-regexp';

export const Routes = {
  callusers: '/callusers',
  users: '/users',
  user: '/user/:id',
  depositAcc: '/depoAcc/:userID/:depoID/',

  auto: `/`,
  melbethistory:'/melbet/history',
  xbet: `/xbet`,
  xbethistory:'/1xbet/history',
  withdraws: `/withdraws`,
  autoDetail: `/auto/:id`,

  khanStatements: `/khan`,
  otp:"/otp",
  //setting

  
  //auth
  login: '/login',
  notfound: '/notfound',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};
