import { useState, useEffect ,useContext} from 'react';
import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { DepositModel } from '../../types';
import { Timestamp } from '@firebase/firestore';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';

import { useCollection, } from '../../helpers/firestore';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from '../../routes/route_helper';
const Users = () => {
  const { id } = useParams()
  const navigate = useNavigate();

  const [melbetIDs, melbetIDsLoading] = useCollection(
    `wazs/${id}/depositAccounts/`,
    'createdAt',
  );
  const columns: ColumnsType<DepositModel> = [
    {
      key: 'type',
      title: 'type',
      dataIndex: 'type',

    },
    {
      key: 'accountID',
      title: 'accountID',
      dataIndex: 'accountID',

    },

    {
      key: 'uid',
      title: 'uid',
      dataIndex: 'uid',
    },


    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },

  ];



  const onClickRow = (d: any) => {
    console.log(d)
    navigate(Routes.compilePath(Routes.depositAcc, { userID:id,depoID: d.uid }));
  };

  return (
    <div>
      <DataTable
        tableName="Bet accounts"
        columns={columns}
        data={melbetIDs || []}
        loading={melbetIDsLoading}
        onClickRow={onClickRow}

      />
    </div>
  );
};

export default Users;
